import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'redirect', pathMatch: 'full' },
  {
    path: 'home', loadChildren: () =>
      import('./project/postlogin/postlogin.module').then((m) => m.PostloginModule)
  },
  {
    path: '', loadChildren: () =>
      import('./project/prelogin/prelogin.module').then((m) => m.PreloginModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
