import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SpinnerInterceptor } from './interceptors/spinner.interceptor';
import { BusinessAccountInterceptor } from './interceptors/business-account.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AppInitService } from './app-init.service';
import { ToastrModule } from 'ngx-toastr';
import { MatStepperModule } from '@angular/material/stepper';
import { StoreModule } from '@ngrx/store';
import { CacheInterceptor } from './interceptors/cache.interceptor';


// APP INITIALIZATION
export function StartupServiceFactory(appInitService: AppInitService) {
  return () => appInitService.Init();
}
const APPINIT_PROVIDES = [AppInitService, {
  provide: APP_INITIALIZER,
  useFactory: StartupServiceFactory,
  deps: [AppInitService],
  multi: true,
}];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    StoreModule.forRoot({}),
    ToastrModule.forRoot({
      timeOut: 2500,
      positionClass: 'toast-center-center', // Custom position class
      closeButton:true

    }),
    MatStepperModule
  ],
  providers: [
    APPINIT_PROVIDES,
    AppInitService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BusinessAccountInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
