import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BusinessAccountService } from 'src/app/project/postlogin/business-account/business-account.service';
import { ApiService } from 'src/app/service/api.service';
import { UomService } from 'src/app/service/uom.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit {
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<any>(
    this.allowMultiSelect,
    this.initialSelection
  );

  imgUrl: any = environment.imgUrl;
  @Input() set data(tableData) {
    this.tableData = tableData;
    this.loadTable();
  }
  @Input() pageConfig: any;

  @Input() set filterData(data) {
    this.dataSource.filter = data ? data.trim().toLowerCase() : '';
  }
  @Input() set searchTerm(value) {
    this.dataSource.filter =
      (typeof value === 'string' ? value.trim().toLowerCase() : value) || '';
    this.getSearchValue = value;
  }
  @Output() actionClick = new EventEmitter();
  @Output() rowSelection = new EventEmitter();
  @Input() width: any='89vw';
  @Input() height: any='unset';
  @Input() headers: any;
  @Input() actions: any = [{ label: '', icon: '' }];
  @Input() userShow: boolean = false;

  @Output() pageChange = new EventEmitter();
  @Output() sortChange = new EventEmitter();
  @Output() editData = new EventEmitter();
  @Output() uomChange = new EventEmitter();
  @ViewChild(MatSort, { static: false }) set sort(sortElm: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = sortElm;
    }
    this.sortTable = sortElm;
  }

  @ViewChild('paginator') paginator: MatPaginator;

  public config: any;
  public tableData: any;
  public businessId: any;
  public dataSource = new MatTableDataSource();
  public filter: any = [];
  public getSearchValue: any;
  public displayedColumns: any[];
  private sortTable: MatSort;

  @Output() rowChange = new EventEmitter<{
    row: any;
    field: string;
    value: any;
  }>();

  customFilterPredicate(data: any, filterObj: any): boolean {
    let result = true;
    if (typeof filterObj === 'object') {
      for (const key in filterObj) {
        if (filterObj.hasOwnProperty(key)) {
          const filterValue = filterObj[key];
          if (key !== 'query') {
            if (filterValue) {
              let finalRes = data[key];
              if (finalRes) {
                result =
                  result &&
                  finalRes
                    .toString()
                    .toLowerCase()
                    .includes(filterValue.toLowerCase());
              } else {
                result = false;
              }
            }
          } else {
            const dataStr = Object.keys(data).reduce((acc, curKey) => {
              let finalRes = data[curKey];
              return acc + finalRes;
            }, '');
            result =
              result &&
              dataStr.toLowerCase().includes(filterValue.toLowerCase());
          }
        }
      }
    } else {
      const dataStr = Object.keys(data).reduce((acc, curKey) => {
        let finalRes = data[curKey];
        return acc + finalRes;
      }, '');
      result = dataStr.toLowerCase().includes(filterObj.toLowerCase());
    }
    return result;
  }

  constructor(
    public uomService: UomService,
    public apiService: ApiService,
    public router: Router,
    public businessAccountService: BusinessAccountService
  ) {}

  ngOnInit(): void {
    this.displayedColumns = this.headers.map((col) => col.prop);
    this.businessId = this.businessAccountService.currentBusinessAccountId;
  }

  ngAfterViewInit(): void {
    this.sortTable.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges() {
    if (this.dataSource) {
      this.dataSource.filter =
        (typeof this.getSearchValue === 'string'
          ? this.getSearchValue.trim().toLowerCase()
          : this.getSearchValue) || '';
    }
  }

  onActionArrayClick(action, rowIndex, row) {
    this.actionClick.emit({ action, rowIndex, row });
  }

  loadTable() {
    this.dataSource = new MatTableDataSource(this.tableData);
    this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
  }

  editRecord(index, data): void {
    this.editData.emit({ index, data });
  }

  onPageChange(data): void {
    this.pageChange.emit(data);
  }

  onUOMChange(data) {
    this.uomChange.emit(data);
  }

  onSortChange(sortCol: Sort): void {
    let sortData = null;
    sortData = sortCol;
    this.sortChange.emit(sortCol);
  }
  gotToLink(link: any) {
    this.router.navigateByUrl(link);
  }

  // Function to get the minimum width based on column properties
  getMinWidth(col: any): string {
    if (col?.minWidth) {
      return col.minWidth; // If maxWidth is defined in column object
    }
    switch (col.prop) {
      case 'addressLine':
        return '150px'; // Minimum width for addressLine and description columns
      case 'description':
        return '300px'; // Minimum width for addressLine and description columns
      case 'transactionId':
        return '200px'; // Custom min-width for transactionId (example)
      // Add more conditions as necessary
      default:
        return 'unset'; // Default value for columns without specific conditions
    }
  }

  // Function to get the maximum width (as per your original logic)
  getMaxWidth(col: any): string {
    if (col?.maxWidth) {
      return col.maxWidth; // If maxWidth is defined in column object
    }
    if (col.prop === 'productCode' || col.prop === 'templateCode') {
      return '300px'; // Example: Set max width for productCode and templateCode
    }
    return 'unset'; // Default for columns without specific maxWidth
  }

  /// related to invite

  check(action, row) {
    if (action.label == 'Resend') {
      return row?.showResendInviteButton ? true : false;
    }
    if (action.label == 'Accept') {
      return row?.showAcceptRelationButton ? true : false;
    }
    if (action.label == 'Reject') {
      return row?.showRejectRelationButton ? true : false;
    }
    return true;
  }

  navigateToPayment(row) {
    this.router.navigateByUrl(
      'home/quick-checkout/order/' + row.id + '?currentMainIndex=1'
    );
  }

  onEdit(event, row: any, field: string) {
    this.rowChange.emit({ row, field, value: event });
  }

  isOptionDisabled(item, items) {
    return items?.includes(item?.id?.toString()) ? true : false;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row: any) =>
          this.selection.select(row.id)
        );
        this.rowSelection.emit(this.selection.selected);
  }

  onSelectRow(row) {
    this.selection.toggle(row.id);
    this.rowSelection.emit(this.selection.selected);
  }

  clearSelection(): void {
    this.selection.clear();
  }


}
