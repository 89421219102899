import { Component, OnInit, Input, forwardRef, ChangeDetectorRef, AfterContentChecked, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'dadyin-button',
    templateUrl: './dadyin-button.component.html',
    styleUrls: ['./dadyin-button.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DadyinButtonComponent), multi: true },
    ]
})
export class DadyinButtonComponent implements OnInit {

    @Input() label = "";
    @Input() theme = "default"; // value will be : default , primary , secondary , danger , warning
    @Input() class = '';
    @Input() size = ''; // value will be : full , small
    @Input() type = "normal"; // value will be : normal , icon , image;
    @Input() typeval = '';
    @Input() height = '';
    @Input() width = '';
    @Input() isDisabled = false;
    @Input() tooltip = '';
    @Output('clicked') clicked= new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {
    }

    onClick() {

        this.clicked.emit()
    }

}